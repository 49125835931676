@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    @apply inline-block;
  }
  button {
    -webkit-tap-highlight-color: transparent;
  }
}

@layer utilities {
  .border-spacing {
    border-spacing: 0.5rem;
  }
}

.hide-button-flash {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#gradient-canvas-beige {
  width: 100%;
  height: 100%;
  --gradient-color-1: #e5c3a6;
  --gradient-color-2: #ead7bb;
  --gradient-color-3: #e5c3a6;
  --gradient-color-4: #bca37f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
